/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <Fragment>
      {/* <div css={[style.top__bar, scrolled && style.top__bar__scrolled]}>
        <div css={style.top__bar__item}>
          <SiteLink to="/dining"> DINING </SiteLink>
        </div>
        <div css={style.top__bar__item} className = "bar"> | </div>
        <div css={style.top__bar__item}>
          <SiteLink to="/front-bar"> FRONT BAR </SiteLink>
        </div>
        <div css={style.top__bar__item} className = "bar"> | </div>
        <div css={style.top__bar__item}>
          <SiteLink to="/menus"> MENUS </SiteLink> 
        </div>
        <div css={style.top__bar__item} className = "bar"> | </div>
        <div css={style.top__bar__item}>
          <SiteLink to="/whats-on"> WHAT'S ON </SiteLink> 
        </div>
        <div css={style.top__bar__item} className = "bar"> | </div>
        <div css={style.top__bar__item}>
          <SiteLink to="/cellars"> CELLARS </SiteLink> 
        </div>
        <div css={style.top__bar__item} className = "bar"> | </div>
        <div css={style.top__bar__item}>
          <SiteLink to="/about"> ABOUT </SiteLink> 
        </div>
        <div css={style.top__bar__item} className = "bar"> | </div>
        <div css={style.top__bar__item}>
          <SiteLink to="#contact"> CONTACT </SiteLink> 
        </div>
        <div css={style.top__bar__item} className = "bar"> | </div>
        <div css={style.top__bar__item} style={{paddingTop : '0px'}}>
          <SiteLink className="btn btn-primary" to="https://bookings.nowbookit.com/?accountid=3bf18ed6-ecfc-4ac9-bb5b-b7e467fbbe84&venueid=3706&theme=light&colors=hex,c41411"> BOOK NOW </SiteLink> 
        </div>
      </div> */}

      <header css={[style.top, scrolled && style.topScrolled]} id='top'>
        <BackButton />

        <div css={[style.top__bar, scrolled && style.top__bar__scrolled]}>
          <div css={style.top__bar__item}>
            <SiteLink to='/' onClick={backTop}>
              {" "}
              HOME{" "}
            </SiteLink>
          </div>
          <div css={style.top__bar__item} className='bar'>
            {" "}
            |{" "}
          </div>
          {/* <div css={style.top__bar__item}>
            <SiteLink to="/front-bar"> FRONT BAR </SiteLink>
          </div>
          <div css={style.top__bar__item} className="bar"> | </div> */}
          <div css={style.top__bar__item}>
            <SiteLink to='/menu'> MENU </SiteLink>
          </div>
          <div css={style.top__bar__item} className='bar'>
            {" "}
            |{" "}
          </div>
          <div css={style.top__bar__item}>
            <SiteLink to='/#whatson'> WHAT'S ON </SiteLink>
          </div>
          <div css={style.top__bar__item} className='bar'>
            {" "}
            |{" "}
          </div>
          <div css={style.top__bar__item}>
            <SiteLink to='/#cellars'> CELLARS </SiteLink>
          </div>
          <div css={style.top__bar__item} className='bar'>
            {" "}
            |{" "}
          </div>
          <div css={style.top__bar__item}>
            <SiteLink to='/about'> ABOUT </SiteLink>
          </div>
          <div css={style.top__bar__item} className='bar'>
            {" "}
            |{" "}
          </div>
          <div css={style.top__bar__item}>
            <SiteLink to='https://giftcards.nowbookit.com/cards/card-selection?accountid=3bf18ed6-ecfc-4ac9-bb5b-b7e467fbbe84&venueid=3706&theme=light&accent=254,86,33'> E-GIFT CARD </SiteLink>
          </div>
          <div css={style.top__bar__item} className='bar'>
            {" "}
            |{" "}
          </div>
          <div css={style.top__bar__item}>
            <SiteLink to='#contact'> CONTACT </SiteLink>
          </div>
          <div css={style.top__bar__item} className='bar'>
            {" "}
            |{" "}
          </div>
          <div css={style.top__bar__item} style={{ paddingTop: "0px" }}>
            <SiteLink className='btn btn-primary' to='/#booknow'>
              {" "}
              BOOK NOW{" "}
            </SiteLink>
          </div>
        </div>

        <SiteLink
          to='/'
          css={[style.logo, scrolled && style.logoScrolled]}
          onClick={backTop}
        >
          <img css={style.logoImg} src='/assets/logos/logo.png' alt='logo' />
        </SiteLink>

        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}
